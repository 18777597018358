<template>
  <div class="clients-container">
    <modal-vue @onChange="onChangeModal" :modal="modal">
    </modal-vue>
    <admin-header-vue
      v-if="showCompany"
      alias="Company"
      @onCreate="onCreate"
      pageName="" />
    <div class="user-table">
      <div v-if="loading" class="local-loader-container">
          <div class="inava-loader"></div>
      </div>
      <table-vue
        :labels="labels"
        :data="companyFilteredList"
        :dateFormat="dateFormat"
        :sortObj="sort"
        :totalCount="totalCount"
        :currentPage="currentPage"
        :limit="limit"
        :sortValidation="sortValidation"
        :titleData="'Compnaies'"
        :showEditAction="showEdit"
        :showDeleteAction="showDelete"
        :showAction="showEdit || showDelete"
        @onUpdate="onUpdate"
        @onChangeSort="onChangeSort"
        @updatePage="updatePage"
        @updateLimit="updateLimit"
        @searchText="searchText"
        :loading="loading" />
    </div>
  </div>
</template>

<script>
import { metaTitle } from '../../../../constant/data'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import ModalVue from '../../../Admin/components/Modal.vue'
import tableVue from '../../components/table.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Companies',
  metaInfo () {
    return {
      title: metaTitle.companies
    }
  },
  components: {
    AdminHeaderVue,
    ModalVue,
    tableVue
  },
  data () {
    return {
      modal: false,
      labels: [
        { name: 'Company Id', alias: 'company_id' },
        { name: 'Company Name', alias: 'company_name' },
        { name: 'Alias', alias: 'alias' },
        { name: 'Updated At', alias: 'updated_at' }],
      sortValidation: ['company_id', 'company_name'],
      dateFormat: ['updated_at'],
      search: '',
      sort: { by: 'asc', field: 'company_id' }
    }
  },

  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('adminCommon', ['tableSelectionState']),
    ...mapState('company', ['companyFilteredList', 'totalCount', 'loading']),

    showCompany () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.write)].length) {
        return true
      }
      return false
    },
    showEdit () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.update)].length) {
        return true
      }
      return false
    },
    showDelete () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.delete)].length) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.company.limit : 10
      },
      set (newLimit) {
        this.tableSelectionState.company.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.company.currentPage : 1
      },
      set (newPage) {
        this.tableSelectionState.company.currentPage = newPage
      }
    }
  },

  mounted () {
    this.getData()
  },

  methods: {
    ...mapActions('company', ['getCompanyFilteredList']),
    /** This method toggles the visibility of the modal
     * @public
     */
    onChangeModal (value) {
      this.modal = value
    },

    /** This method redirects the user to the *Add/Create Compnay* page
     * @public
     */
    onCreate () {
      this.$router.push('company/add')
    },

    /** This method fetches the list data by calling the action method from the store
     * @public
     */
    getData () {
      this.getCompanyFilteredList({
        limit: this.limit,
        skip: (this.currentPage - 1) * this.limit,
        orderBy: this.sort,
        search: this.search
      })
    },

    /** This method redirects the user to the *Update Company* page
     * @public
     */
    onUpdate (data) {
      this.$router.push(`company/update/${data.company_id}`)
    },

    /** This method handels the sorting based on the column header clicked
     * @public
     */
    onChangeSort (type) {
      this.sort = type
      // console.log('ToChangeSort')
      this.getData()
    },

    /** This method handels the navigation between the pagination.
     * @public
     */
    updatePage (data) {
      this.currentPage = data
      // console.log('updatePage')
      this.getData()
    },

    /** This method updates the number of records on one page in the pagination
     * @public
     */
    updateLimit (data) {
      this.limit = data
      // console.log('updateLimit')
      this.getData()
    },

    /** This method handles the input in the search field and calls the action to get the search result.
     * @public
     */
    searchText (text) {
      this.search = text
      if (this.search !== null) {
        this.currentPage = 1
        this.getData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.clients-container {
  margin: 20px 0px;
  border-radius: 4px;
  background: var(--primary);

  .user-table{
    position: relative;

  .local-loader-container {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--primary);
      background: rgba(0, 0, 0, 0.4);
      z-index: 9;
      .inava-loader {
        position: relative;
        display: flex;
        margin: auto;
        border-radius: 50%;
        border: none;
        z-index: 900;
        width: 70px;
        height: 70px;
        background-image: '@/assets/Inava-logo-GIF.gif';
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>
